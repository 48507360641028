/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
// require('../video-js/video-js.min.css');
// //require('../icons/feather-icons-web/feather.css');
// // require('../icons/gtue-icon/css/gtue_iconfont.css');
// require('../css/app.scss');
//
// //require('../js/jquery.scrollTo.min.js');
// //require('../js/jquery.event.move.js');
// require('../js/simpleLightbox.min.js');
// require('../js/jquery.event.move.js');
// // require('../js/jquery.easing.min');
// require('../website/js/frontend.js'); // Gleiche Datei für www und PB!!
//
// require('../js/jquery.scrollTo.min');
// require('../js/simpleScroller.js');
// require('../js/gtue.js');

// loading CSS
import 'video.js/dist/video-js.min.css';
import 'jstree/dist/themes/default/style.min.css'

// loading JS
import '../css/app.scss'
import $ from 'jquery'

window.$ = window.jQuery = $;

// node modules
import 'jquery.event.move'
import 'jquery-bridget'
import 'isotope-layout'
//import 'jquery.scrollto'
//import 'datatables'
import 'video.js'
import 'jstree';

// local modules
import './jquery.scrollTo.min'
import './simpleScroller'
import './checkify/jquery.checkify';
import './simpleLightbox.min'

// custom scripts
import './jquery.dataTables.min'
import './date-de'
import '../website/js/frontend';
import './gtue'
import './partnernetz';
import './scripts/asJsTree';
import './recruiting-pakete';

let jQueryBridget = require('jquery-bridget');
let Isotope = require('isotope-layout');
// make Isotope a jQuery plugin
jQueryBridget('isotope', Isotope, $);

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');
//require('../video-js/video.min.js');




