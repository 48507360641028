$(function () {
    let tree = $('#as-jstree');
    tree.jstree({
        'core': {
            'data': {
                'url': function (node) {
                    return node.id === '#' ? tree.data('path') + '?path=' : tree.data('path') + '?path=' + node.data.url;
                },
                'dataType': 'json',
            },
        }
    }).on("activate_node.jstree", function (e, data) {
        let node = data.node;

        if (node.data.type !== 'dir') {
            if (node.data.url && node.data.ext !== 'pdf' && node.data.ext !== 'url') {
                window.location.href = node.data.url;
            } else {
                window.open(node.data.url, '_blank');
            }
        }
    }).on('open_node.jstree', function (e, data) {
        $(this).jstree('set_icon', data.node, '/frontend_build/images/icons/as_icons/Icon_Ordner-offen.svg');

    }).on('close_node.jstree', function (e, data) {
        $(this).jstree('set_icon', data.node, '/frontend_build/images/icons/as_icons/Icon_Ordner.svg');
    })
});