import $ from 'jquery'
import videojs from 'video.js'

$(document).ready(function(){
  /*-------------------
  StickyNav START
  ---------------------*/
// When the user scrolls the page, execute myFunction
  window.onscroll = function() {setFixedHeader()};

// Get the navbar
  var navbar = document.getElementById("gtue-header");

// Get the offset position of the navbar
  var sticky = navbar.offsetTop;

// Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
  function setFixedHeader() {
    if (window.pageYOffset >= sticky + 50) {
      $('html').addClass("sticky");
    } else {
      $('html').removeClass("sticky");
    }
  }
  /*--- StickyNav END */

  /*-------------------
  Scrollto START
  ---------------------*/
  $("a.scrollTo").on('click',function(event) {
    event.preventDefault();
    //console.log($(this).attr('href'));
    var url = $(this).attr('href');
    var hash = url.substring(url.indexOf("#")+1);

    let pos;
    if(hash == 'top') {
      pos = 0;
    }else{
      pos = $('#'+hash).offset().top;
    }
    $('html, body').animate({
      scrollTop: pos
    }, 500);
    return false;
  });
  /*--- Scrollto END */

  /*-------------------
  Anchor Offset START
  ---------------------*/
  let url = window.location.href;
  function anchorOffset(){
    var target = url.split('#')[1];
    $('html, body').animate({
      scrollTop: $('#'+target).offset().top - 110
    }, 1000);
  }

  if(url.indexOf('#') > -1){
    anchorOffset();
  }



  /*--- Anchor Offset END */



  /*-------------------
  Simplescroller by Alex START
  ---------------------*/
  $("div.slider-container ul").simpleScroller({
    counter: false, // Zähler
    auto: false, // Automatisches Blättern
    pause: 4000, // Pause bei automatischem Blättern
  });

  /*--- Simplescroller by Alex END */

  /*-------------------
  simpleLightbox START
  ---------------------*/
  $('.scaleable a, a.scaleable').simpleLightbox({
    nextOnImageClick: false,
    loadingCaption: 'Wird geladen...'
  });
  $('.slider-container a').simpleLightbox({
    beforeClose: function(){
      $('html').removeClass('gallerylightbox');
    }
  }).on( 'click', function( e ) {
    $('html').addClass('gallerylightbox');
  });



  /*--- simpleLightbox END */


  /*-------------------
  SidebarNavi START
  ---------------------*/
  //Falls der User mit der Tastatur die Navi erreicht.
  $('.sidebar-containers nav input[type="checkbox"]').on( 'keydown', function( e ) {
    //Wenn TAB gedrückt wird.
    if( e.which == 9 ) {
      $(this).attr('checked', function(_, attr){ return !attr});
    }
  });
  /*--- SidebarNavi END */

  /*-------------------
  MainNavi START
  ---------------------*/
  //Falls der User mit der Tastatur die Navi erreicht.

  var lastchild = $('.gtue-main-navi li:last-child a:last');
  var mainLinks = $('#gtue-top-of-page > div > ul > li');

  $('#gtue-top-of-page > div > ul > li > a,.gtue-meta a').on( 'keyup', function( e ) {
    //Wenn TAB gedrückt wird.
    var focused = $(':focus');

    if( e.which == 9 ) {
      mainLinks.removeClass('hover');
      focused.parent().addClass('hover');

      //Navi schließen nach dem letztem Link
      lastchild.focusout(function() {
        mainLinks.removeClass('hover');
      })
    }
  });

  //Navi schließen wenn ESC gedrückt wurde.
  $('body').on( 'keyup', function( e ) {
    if( e.which == 27 ) {
      mainLinks.removeClass('hover');
    }
  })


  //Mobile, offene Punkte schließen
  $('.gtue-main-navi label').on( "click", function(event){
    if($(this).prev('input:checked').length > 0) {
      event.preventDefault();
      $(this).prev('input:checked').prop("checked", false);
    }
  });

  /*--- MainNavi END */


  /*-------------------
  OpenClose Mobile Search START
  ---------------------*/

  if($(window).width() < 992){
    $('.gtue-search-wrapper button').on( 'click', function(event) {
      if(!$('html').hasClass('open-search')){
        event.preventDefault();
        $('.gtue-search-wrapper input').focus();
      }
      $('html').toggleClass('open-search');
    });
  }

  /*--- OpenClose Mobile Search END */

  /*-------------------
  2nd Scrollbar START
  ---------------------*/
  /*
      var running = false;
      $(".top-scroller .fake-content").width($(".kba .table-responsive table").width());
      $(".top-scroller").scroll(function(){
          if(running) {
              running = false;
              return;
          }
          running = true;
          $(".table-responsive")
              .scrollLeft($(".top-scroller").scrollLeft());
      });
      $(".table-responsive").scroll(function(){
          if(running) {
              running = false;
              return;
          }
          running = true;
          $(".top-scroller")
              .scrollLeft($(".table-responsive").scrollLeft());
      });
*/
  /*--- 2nd Scrollbar END */


  /*-------------------
  VIDEO JS START
  ---------------------*/
  $('.video-js').each(function (index, element) {
    var tmpvideo = videojs(this)// element == this
    //aspectRatio
  });

  /*--- VIDEO JS END */

  /*-------------------
  Mobile Navi START
  ---------------------*/
  $('#gtue-top-of-page .hamburg').on( 'click', function( e ) {
    $('html').toggleClass('open-mobile');
  })
  /*--- Mobile Navi END */


  /*-------------------
  Accordion START
  ---------------------*/
  /* für ganz große Inhalte wird nach .5s die maxheight entfernt. */
  $('.accordion > ul > li > input').on( 'change', function( jQEvent ) {
    var target = $(this);
    window.setTimeout(function(){
      target.parent().toggleClass('active');
    }, 500);
  });

  /*--- Accordion END */

  /*-------------------
  Teaser-Link START
  ---------------------*/
  /* Macht den gesamten Teaser Block klickbar, wenn ur ein Link enthalten ist. */
  $('.start .content-container.col-2 .container').each( function() {
    var $container = $(this);
    var $link = $container.find('a');
    if($link.length === 1) {
      $container.addClass('singleLink');
      $container.not($link).on('click', function (e) {
        if(e.originalEvent && e.originalEvent.target) {
          $link[0].click();
        }
      });
    }
  });
  /*--- Teaser-Link END */


  /*-------------------
  Suche Sortierung START
  ---------------------*/
  /* für ganz große Inhalte wird nach .5s die maxheight entfernt. */
  $('#search_sort > input').on('change', function (jQEvent) {
    $(this).closest('form').submit();
  });
  /*--- Suche Sortierung END */
});

/*-------------------
Formular Serialize
---------------------*/

$.fn.serializeObject = function()
{
  var o = {};
  var a = this.serializeArray();
  $.each(a, function() {

    var name = this.name.match(/\[(.*?)\]/)[1];

    if (o[name] !== undefined) {
      if (!o[name].push) {
        o[name] = [o[this.name]];
      }
      o[name].push(this.value || '');
    } else {
      o[name] = this.value || '';
    }
  });
  return o;
};


