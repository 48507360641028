/* scroller für Bühne etc. */
(function($)
{
	$.fn.simpleScroller = function(params)
	{
		return this.each(function() {
			/* params = Array(
				dur: 1000, // Animationszeit in ms
				auto: true|false, // Autoanimation an/aus
				pause: 5000, //Pause zwischen Autoanimation in ms
				counter: true|false, // Zähler an/aus
				skip: 0, // Anzahl von zu überspringenden Seiten bei next/prev
			);
			*/
			var thisScroller = $(this);

			var visiblePage = thisScroller.children("li:first-child");
			var thisPageFirst = thisScroller.children("li:first-child");
			var thisPageLast = thisScroller.children("li:last-child");
			var isHover = false;
			var thisDuration = 600;
			var thisSkip = 0;
			var thisDir = 'horizontal';
			if (typeof(params) === "object") {
				if (params.dur) {
					thisDuration = params.dur;
				}
				if (params.auto) {
					var thisAuto = true;
					if (params.pause) {
						var thisPause = params.pause;
					}
					else {
						var thisPause = 4000;
					}
				}
				if (params.skip) {
					thisSkip = params.skip;
					if (thisSkip > thisScroller.children("li").length) {
						thisSkip = thisScroller.children("li").length - 1;
					}
					if (thisSkip < 0) {
						thisSkip = 0;
					}
				}
				if (params.dir) {
					thisDir = params.dir;
				}
			}

			thisScroller.addClass("js").parent().addClass("js");
			thisScroller.append(thisScroller.children("li")); // Leerzeichen zwischen li-Tags entfernen.

			if (thisDir == 'horizontal') {

				thisScroller.css({
					overflowX: "hidden"
				});

				if ((thisScroller.innerWidth() < thisPageLast.width() * thisScroller.children("li").length) || ((thisPageLast.offset().left - thisScroller.offset().left) > (thisScroller.innerWidth() - thisPageLast.width()))) {
					var thisLeft = $('<button class="scroller_left" title="Nach links"></button>');
					thisScroller.after(thisLeft);
					var thisRight = $('<button class="scroller_right" title="Nach rechts"></button>');
					thisScroller.after(thisRight);

					var thisCounter = $('<div class="scroller_counter"></div>');
					if (typeof(params) === "object" && params.counter) {
						thisScroller.after(thisCounter);
					}

					thisScroller.children("li").each(function (i) {
						var thisPage = $(this);
						var thisPageCounter = $('<a href="#' + (i+1) + '" title="Seite ' + (i+1) + '"><strong>' + (i+1) + '</strong></a>');
						thisCounter.append(thisPageCounter);
						thisPage.data("counter", thisPageCounter);

						// Klone zum scrollen über start/ende anlegen.
						thisPageFirst.before(thisPage.clone());
						thisPageFirst.prev("li").data("counter", thisPageCounter);

						thisScroller.append(thisPage.clone());
						thisScroller.children("li:last-child").data("counter", thisPageCounter);


						thisPageCounter.click(function () {
							visiblePage = thisPage;
							thisScroller.scrollTo(visiblePage, thisDuration, {offset: -(thisScroller.innerWidth() - visiblePage.outerWidth()) / 2});
							thisCounter.children("a").removeClass("on");
							thisPageCounter.addClass("on");
							return false;
						});

					});

					visiblePage.data("counter").addClass("on");

					thisLeft.click(function () {
						if (thisTimer) // reset autoanim
						{
							window.clearTimeout(thisTimer);
						}
						if (!thisScroller.is(":animated") || isHover == "left") {
							visiblePage = visiblePage.prevAll("li").eq(thisSkip);
							var thisEasing = "swing";
							if (isHover == "left") {
								thisEasing = "linear";
							}

							thisScroller.scrollTo(
								visiblePage,
								thisDuration,
								{
									easing: thisEasing,
									offset: -(thisScroller.innerWidth() - visiblePage.outerWidth()) / 2,
									onAfter: function () {
										// Erstes (echtes) Element rechts rausgescrollt? (=>Nur Klone sichtbar) Scrolle zurück in Mitte
										if ((thisPageFirst.offset().left - thisScroller.offset().left) >= thisScroller.innerWidth()) {
											visiblePage = visiblePage.nextAll("li:eq(" + (thisScroller.children("li").length / 3 - 1) + ")")
											thisScroller.scrollTo(visiblePage, 0, {offset: -(thisScroller.innerWidth() - visiblePage.outerWidth()) / 2});
										}
										if (isHover == "left") {
											thisLeft.click();
										}
									}
								}
							);

							thisCounter.children("a").removeClass("on");
							visiblePage.data("counter").addClass("on");
						}
						if (thisAuto) //autoanim
						{
							thisTimer = window.setTimeout(function () {
								thisRight.click();
							}, thisPause);
						}
						return false;
					});

					thisRight.click(function () {
						if (thisTimer) // reset autoanim
						{
							window.clearTimeout(thisTimer);
						}
						if (!thisScroller.is(":animated") || isHover == "right") {
							visiblePage = visiblePage.nextAll("li").eq(thisSkip);
							var thisEasing = "swing";
							if (isHover == "right") {
								thisEasing = "linear";
							}

							thisScroller.scrollTo(
								visiblePage,
								thisDuration,
								{
									easing: thisEasing,
									offset: -(thisScroller.innerWidth() - visiblePage.outerWidth()) / 2,
									onAfter: function () {
										// Letztes (echtes) Element links rausgescrollt? (=>Nur Klone sichtbar) Scrolle zurück in Mitte
										if ((thisPageLast.offset().left - thisScroller.offset().left) < 0) {
											visiblePage = visiblePage.prevAll("li:eq(" + (thisScroller.children("li").length / 3 - 1) + ")")
											thisScroller.scrollTo(visiblePage, 0, {offset: -(thisScroller.innerWidth() - visiblePage.outerWidth()) / 2});
										}
										if (isHover == "right") {
											thisRight.click();
										}
									}
								}
							);

							thisCounter.children("a").removeClass("on");
							visiblePage.data("counter").addClass("on");
						}
						if (thisAuto) //autoanim
						{
							thisTimer = window.setTimeout(function () {
								thisRight.click();
							}, thisPause);
						}
						return false;
					});

					if (typeof(params) === "object" && params.hover == true) {
						thisLeft.hover(
							function () {
								isHover = "left";
								thisLeft.click();
							},
							function () {
								isHover = false;
							}
						);
						thisRight.hover(
							function () {
								isHover = "right";
								thisRight.click();
							},
							function () {
								isHover = false;
							}
						);
					}


					// swiping
					var scrollStart = 0;
					thisScroller.on('movestart', function (e) {
						if (thisTimer) // reset autoanim
						{
							window.clearTimeout(thisTimer);
						}
						if (Math.abs(e.distX) < Math.abs(e.distY)) // reaktiviere hoch/runter scrollen der gesamten Seite
						{
							e.preventDefault();
						}

						scrollStart = thisScroller.scrollLeft();
					});

					thisScroller.on('move', function (e) {
						if (Math.abs(e.distX) > Math.abs(e.distY)) // seitlich scrollen der Bühne umsetzen
						{
							thisScroller.scrollTo({top: 0, left: "-=" + (e.deltaX / 1) + "px"}, 0);
						}
					});

					thisScroller.on('!moveend', function (e) {

						if ($("#debug").length == 0) {
							$("body").append("<div id='debug'></div>");
							$("#debug").css({
								position: "fixed",
								zIndex: 9999999999999999,
								left: 0,
								top: 0,
								background: 'black',
								padding: '10px',
								color: 'white',
								whiteSpace: 'pre'
							});
						}
						$("#debug").text("distX: " + e.distX + ";\nscrolling: " + (scrollStart - thisScroller.scrollLeft()) + " (" + scrollStart + " - " + thisScroller.scrollLeft() + ");\ndiff: " + (e.distX - (scrollStart - thisScroller.scrollLeft())));
						if (Math.abs(e.distX) > Math.abs(e.distY)) // seitlich scrollen der Bühne umsetzen
						{
							//$("#debug").text(e.distX + " | " + e.deltaX + " > " + Math.round(e.deltaX * thisScroller.innerWidth() / 25) + "px");
							//thisScroller.scrollTo({top: 0, left: "-=" + Math.round(e.deltaX * thisScroller.innerWidth() / 25) + "px"}, 200);
							thisScroller.scrollTo({
								top: 0,
								left: "-=" + (e.distX - (scrollStart - thisScroller.scrollLeft())) + "px"
							}, 0);
						}
					});

					thisScroller.on('moveend', function (e) {

						thisScroller.scrollTo({
								top: 0,
								left: "-=" + (e.distX - (scrollStart - thisScroller.scrollLeft())) + "px"
							}, 0, // Abgleichen von angeforderter und gescrollter Distanz.
							{
								onAfter: function () {
									thisScroller.find("li").each(function () {
										var thisPage = $(this);
										if (thisPage.offset().left > thisScroller.offset().left + thisScroller.innerWidth() / 2) // Finde angescrolltes Element
										{
											return false;
										}
										visiblePage = $(this);
									});
									thisScroller.scrollTo(
										visiblePage,
										200,
										{
											offset: -(thisScroller.innerWidth() - visiblePage.outerWidth()) / 2,
											onAfter: function () {
												// Letztes (echtes) Element links rausgescrollt? (=>Nur Klone sichtbar) Scrolle zurück in Mitte
												if ((thisPageLast.offset().left - thisScroller.offset().left) < 0) {
													visiblePage = visiblePage.prevAll("li:eq(" + (thisScroller.children("li").length / 3 - 1) + ")")
													thisScroller.scrollTo(visiblePage, 0, {offset: -(thisScroller.innerWidth() - visiblePage.outerWidth()) / 2});
												}
												// Erstes (echtes) Element rechts rausgescrollt? (=>Nur Klone sichtbar) Scrolle zurück in Mitte
												else if ((thisPageFirst.offset().left - thisScroller.offset().left) >= thisScroller.innerWidth()) {
													visiblePage = visiblePage.nextAll("li:eq(" + (thisScroller.children("li").length / 3 - 1) + ")")
													thisScroller.scrollTo(visiblePage, 0, {offset: -(thisScroller.innerWidth() - visiblePage.outerWidth()) / 2});
												}
												if (thisAuto) //autoanim
												{
													thisTimer = window.setTimeout(function () {
														thisRight.click();
													}, thisPause);
												}
											}
										}
									);

									thisCounter.children("a").removeClass("on");
									//visiblePage.data("counter").addClass("on");
								}
							});
					});


				}
			}
			else { // vertikales scrollen?

				thisScroller.css({
					overflowY: 'hidden'
				});

				if (thisScroller.innerHeight() < (thisPageLast.position().top + thisPageLast.outerHeight())
					|| thisPageFirst.position().top < 0) {
					var thisUp = $('<button class="scroller_up" title="Nach oben"></button>');
					thisScroller.after(thisUp);
					var thisDown = $('<button class="scroller_down" title="Nach unten"></button>');
					thisScroller.after(thisDown);

					thisDown.click(function () {
						thisScroller.animate({
							scrollTop: '+=' + (thisScroller.innerHeight())
						}, thisDuration);
						return false;
					});

					thisUp.click(function () {
						thisScroller.animate({
							scrollTop: '-=' + (thisScroller.innerHeight())
						}, thisDuration);
						return false;
					});
				}
			}

			if(thisAuto)
			{ var thisTimer = window.setTimeout(function(){ thisRight.click(); }, thisPause); }

			var scaleTimer = false;
			$(window).resize(function()
			{
				window.clearTimeout(scaleTimer);
				scaleTimer = window.setTimeout(function(){ // Ansicht zentrieren mit minimaler Verzögerung für Rendern.
					thisScroller.scrollTo(visiblePage, 0, { offset: -(thisScroller.innerWidth() - thisPageFirst.outerWidth()) / 2 });
				}, 100);
			});

			window.setTimeout(function(){ // Ansicht zentrieren mit minimaler Verzögerung für Rendern.
				thisScroller.scrollTo(thisPageFirst, 0, { offset: -(thisScroller.innerWidth() - thisPageFirst.outerWidth()) / 2 });
			}, 10);


		});

	}
})(jQuery);

