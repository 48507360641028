document.addEventListener('DOMContentLoaded', () => {

    const table = document.querySelector('.recruiting-pakete');

    //Checks if element exists
    if(table){

        const form = document.querySelector('.recruiting-pakete-form');
        const table = document.querySelector('.recruiting-pakete');
        const reset = document.getElementById('recruiting_pakete_clear');
        const close = document.querySelector('.close-form');
        const back = document.querySelector('.back-to-pakete');
        const errors = document.querySelector('.recruiting-pakete-errors');

        let activePackage;

        //Event listener for opening the modal
        const btns = document.querySelectorAll('.open-form');
        btns.forEach(btn => {
            btn.addEventListener('click', openmodal);
        });

        //Event listener for closing the modal
        close.addEventListener('click', closemodal);
        back.addEventListener('click', closemodal);

        //Reset active package
        reset.addEventListener('click', () => {
            setTimeout(() => setActivePackage(activePackage), 1);
        });


        //Opening Modal after error message
        if (errors) {
             form.classList.add('show');
             table.classList.remove('show');
         }

        function openmodal() {
            activePackage = this.dataset.paket;

            form.classList.add('show');
            table.classList.remove('show');

            setActivePackage(activePackage);

            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }

        function closemodal(e) {
            e.preventDefault();
            table.classList.add('show');
            form.classList.remove('show');
        }

        function setActivePackage(val) {
            const select = document.getElementById('recruiting_pakete_paket');
            select.value = val || '';
        }

    }
});
